/**
 * @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers, lifecycle } from 'recompose';
import { map, find, isEqual, get } from 'lodash';
import SalonSelect from '../components/SalonSelect';
import { switchSalon, setIsOwner } from '../../../actions/user';
import { setSettings } from '../../../actions/salon';

// The query to get list of all salons of current user
export const GET_SALONS_QUERY = gql`
    query getSalons {
        viewer {
            id
            ... on Administrator {
                salons {
                    id
                    name
                    administrator {
                        id
                    }
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                        enableArchive
                    }
                }
            }
            ... on Master {
                salons {
                    id
                    name
                    administrator {
                        id
                    }
                    settings {
                        schedule {
                            startAt
                            endAt
                            step
                            format
                        }
                        enableArchive
                    }
                }
            }
        }
    }
`;

const withData = graphql(GET_SALONS_QUERY, {
    props: ({ data: { loading, viewer, error } }) => ({
        loading,
        error,
        viewer,
    }),
});

// Return list of salons as an options for dropdown menu
const props = withProps(
    ({ loading, error, viewer, currentSalonId, currentSalonName }) => {
        if (loading || error) {
            return {};
        }

        let currentSalon = null;
        if (currentSalonId) {
            currentSalon = find(viewer.salons, {
                id: currentSalonId,
            });
            if (!currentSalon && currentSalonName) {
                currentSalon = {
                    id: currentSalonId,
                    name: currentSalonName,
                };
            }
        }
        if (!currentSalon && viewer.salons.length > 0) {
            [currentSalon] = viewer.salons;
        }

        const options = map(viewer.salons, salon => ({
            value: salon.id,
            text: salon.name,
        }));

        return {
            currentSalon,
            options,
        };
    },
);

// Provide ID of current salon
const mapStateToProps = ({ user, salon }) => ({
    currentSalonId: user.get('salon'),
    currentSalonName: user.get('salonName'),
    settings: salon.get('settings'),
    isMaster: user.get('isMaster'),
});

const mapDispatchToProps = {
    switchSalon,
    setIsOwner,
    setSettings,
};

const handlers = withHandlers({
    onChange: ({
        switchSalon,
        setIsOwner,
        setSettings,
        viewer,
        currentSalon,
    }) => salonId => {
        const salon = find(viewer.salons, salon => salon.id === salonId);
        setIsOwner(salon.administrator.id === viewer.id);
        switchSalon(salonId, salon.name);
        setSettings(
            Object.assign(
                { enableArchive: get(currentSalon, 'settings.enableArchive') },
                get(currentSalon, 'settings.schedule'),
            ),
        );
    },
});

const componentWillReceiveProps = ({ settings, currentSalon, setSettings }) => {
    const salonSettings = Object.assign(
        { enableArchive: get(currentSalon, 'settings.enableArchive') },
        get(currentSalon, 'settings.schedule'),
    );
    if (currentSalon.settings && !isEqual(settings, salonSettings)) {
        setSettings(salonSettings);
    }
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withData,
    props,
    handlers,
    lifecycle({ componentWillReceiveProps }),
)(SalonSelect);
